import React, { useEffect, useState } from "react"

import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"

import SEO from "../components/seo"

import firebase from "gatsby-plugin-firebase"

const Finish = () => {
  useEffect(() => {
    const logout = async () => {
      await firebase.auth().signOut()
    }

    logout()
  }, [])

  return (
    <div>
      <SEO title="Donload Aplikasi" />
      <Header />
      <div className="mx-auto" style={{ width: 300 }}>
        <p>
          Selamat Kamu Telah Berhasil Daftar, Silahkan Donload Aplikasi Reseller
          App Di Google Playstore
        </p>
      </div>
      <Footer />
    </div>
  )
}

export default Finish
